<template>
  <div>
    <b-tabs>
      <b-tab active title="English">
        <b-card title="Resort Manager Details">
          <validation-observer ref="basicValidation">
            <b-form>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Manager Name">
                    <validation-provider
                      #default="{ errors }"
                      name="Manager Name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="manager.managerName"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Manager Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Resort Name">
                    <b-form-input
                      v-model="manager.resortName"
                      placeholder="Resort Name"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Phone">
                    <b-form-input
                      v-model="manager.phone"
                      placeholder="Resort Name"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Email">
                    <b-form-input
                      v-model="manager.email"
                      placeholder="Resort Name"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="City">
                    <b-form-input
                      v-model="manager.city"
                      placeholder="Resort Name"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-tab>
      <b-tab title="Hindi">
        <b-card title="Resort Manager Details">
          <validation-observer ref="basicValidation">
            <b-form>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Manager Name">
                    <validation-provider
                      #default="{ errors }"
                      name="Manager Name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="manager.translation.hi.managerName"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Manager Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Resort Name">
                    <b-form-input
                      v-model="manager.translation.hi.resortName"
                      placeholder="Resort Name"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Phone">
                    <b-form-input
                      v-model="manager.translation.hi.phone"
                      placeholder="Resort Name"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Email">
                    <b-form-input
                      v-model="manager.translation.hi.email"
                      placeholder="Resort Name"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="City">
                    <b-form-input
                      v-model="manager.translation.hi.city"
                      placeholder="Resort Name"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-tab>
      <b-tab title="Marathi">
        <b-card title="Resort Manager Details">
          <validation-observer ref="basicValidation">
            <b-form>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Manager Name">
                    <validation-provider
                      #default="{ errors }"
                      name="Manager Name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="manager.translation.mr.managerName"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Manager Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Resort Name">
                    <b-form-input
                      v-model="manager.translation.mr.resortName"
                      placeholder="Resort Name"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Phone">
                    <b-form-input
                      v-model="manager.translation.mr.phone"
                      placeholder="Resort Name"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Email">
                    <b-form-input
                      v-model="manager.translation.mr.email"
                      placeholder="Resort Name"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="City">
                    <b-form-input
                      v-model="manager.translation.mr.city"
                      placeholder="Resort Name"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-tab>
    </b-tabs>

    <b-card>
      <b-col class="align-self-center">
        <b-button @click="updateResortManager" size="lg" block variant="primary"
          >Update Resort Manager</b-button
        >
      </b-col>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import { getUserToken } from "@/auth/utils";

import {
  BCard,
  BRow,
  BCol,
  BForm,
  BTabs,
  BTab,
  BFormInput,
  BFormGroup,
  BButton,
  BFormTextarea,
  BFormFile,
  BCardText,
} from "bootstrap-vue";
import axios from "axios";
export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BFormTextarea,
    BFormFile,
    BCardText,
  },
  data() {
    return {
      required,
      resortID: "",
      file: [],
      uploadErrors: [],
      manager: {
        resortName: "",
        managerName: "",
        phone: "",
        email: "",
        city: "",
        translation: {
          hi: {
            resortName: "",
            managerName: "",
            phone: "",
            email: "",
            city: "",
          },
          mr: {
            resortName: "",
            managerName: "",
            phone: "",
            email: "",
            city: "",
          },
        },
      },
    };
  },
  mounted() {
    this.getPage();
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    getPage() {
      console.log("this.$route.params.slug", this.$route.params.slug);
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/org/resortManager/cms/get/${this.$route.params.slug}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.manager = response.data;
          this.resortID = response.data._id;
          const { translation } = response.data;
          this.manager.translation = {
            hi: {
              resortName:
                translation && translation.hi.resortName
                  ? translation.hi.resortName
                  : "",
              managerName:
                translation && translation.hi.managerName
                  ? translation.hi.managerName
                  : "",
              phone:
                translation && translation.hi.phone ? translation.hi.phone : "",
              email:
                translation && translation.hi.email ? translation.hi.email : "",
              city:
                translation && translation.hi.city ? translation.hi.city : "",
            },
            mr: {
              resortName:
                translation && translation.mr.resortName
                  ? translation.mr.resortName
                  : "",
              managerName:
                translation && translation.mr.managerName
                  ? translation.mr.managerName
                  : "",
              phone:
                translation && translation.mr.phone ? translation.mr.phone : "",
              email:
                translation && translation.mr.email ? translation.mr.email : "",
              city:
                translation && translation.mr.city ? translation.mr.city : "",
            },
          };
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast(
            "danger",
            "Error",
            "Error while adding the resort manager!"
          );
        });
    },
    updateResortManager() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/org/resortManager/update`,
          { resortID: this.resortID, updates: this.manager },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.makeToast(
            "success",
            "Success",
            `${response.data.managerName} has been updated successfully!`
          );
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast(
            "danger",
            "Error",
            "Error while updating the resort manager!"
          );
        });
    },
    uploadCSV() {
      const csvFormData = new FormData();
      csvFormData.append("csv-data", this.file);
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/org/resortManager/save`,
          csvFormData,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          if (response.data.length) {
            this.uploadErrors = response.data;
            this.makeToast(
              "danger",
              "Error",
              "Errors found while uploading data!"
            );
          } else {
            this.makeToast("success", "Success", `Uploaded successfully!`);
          }
        });
    },
  },
};
</script>

<style>
</style>